import { Box, BoxProps } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { useMediaQuery } from '@mui/material'

interface BoxThemeProps extends BoxProps {
  title?: string
}

function BoxTheme({ title = '', children, ...rest }: BoxThemeProps) {
  const useStyles = makeStyles({
    root: {
      height: '100%',
      padding: 8,
    },
  })
  const isMobile = useMediaQuery('(max-width:767.98px)')

  const classes = useStyles()
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      p={2}
      height="100%"
      flexGrow={1}
      border={2}
      borderRadius={3}
      borderColor={'#9E9E9E'}
      classes={classes}
      {...rest}
    >
      <span
        style={{
          color: 'var(--color-orange-theme)',
          fontWeight: 'bold',
          fontSize: isMobile ? 14 : 17,
        }}
      >
        {title}
      </span>
      <Box style={{ marginTop: 5 }}>{children}</Box>
    </Box>
  )
}

export default BoxTheme
