import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { Toolbar } from '@mui/material'
import NavRight from '../header-component/NavRight'

type CustomAppBarProps = {
  open: boolean
  toggleDrawer: () => void
  handleChangeView: (view: string) => void
}

const drawerWidth: string = '15%'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundImage:
    'linear-gradient(to right, var(--color-blue-wecitizen), var(--color-green-wecitizen))',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  height: '75px',
  paddingTop: '9px',
  paddingLeft: '2px',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  '@media (max-width: 767.98px)': {
    width: '100%',
  },
}))

const CustomAppBar = (props: CustomAppBarProps) => {
  return (
    <>
      <AppBar open={props.open}>
        <Toolbar>
          <NavRight handleChangeView={props.handleChangeView} />
        </Toolbar>
      </AppBar>
    </>
  )
}

export default CustomAppBar
