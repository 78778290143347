import { Container, IInit } from '../../../common/container/Container'
import { forkJoin, from, Observable, of } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Category, Issue, IssueCategoryName, IssueDTO, IssueQuery } from '../models/Issue'
import { IIssueApi } from '../api/IssueApi'
import { HISTORY, WORKNOTES } from '../api/sampleData'
import { IssueHistory } from '../models/IssueHistory'
import { Worknote } from '../../worknotes/models/Worknote'
import { filter, map, mergeMap, switchMap, toArray } from 'rxjs/operators'
import { IssueType } from '../models/IssueType'
import { User } from '../../users/models/User'
import { IStateService } from './StateService'
import {
  ADDRESS_SERVICE_KEY,
  ISSUEHISTORY_SERVICE_KEY,
  ISSUETYPE_SERVICE_KEY,
  STATE_SERVICE_KEY,
} from '../container'
import { State } from '../models/State'
import { getIssueContainer } from '../../../container/issue-module'
import { IIssueTypeService } from './IssueTypeService'
import { getUserContainer } from '../../../container/user-module'
import { USER_SERVICE_KEY } from '../../users'
import { UserService } from '../../users/services/UserService'
import { IAddressService } from './AddressService'
import { Address } from '../models/Address'
import { isNonUndefined } from '../../../common/utils/collections'
import { IIssueHistoryService } from './IssueHistoryService'
import { Query } from 'common/api/Query'
import { FileDTO, File as F } from '../../files/models/File'

type Props = {
  apiKey: symbol
}

export interface IIssueService extends IInit {
  getByID(id: string): Observable<Issue | undefined>

  getByWorkerID(id: string): Observable<Issue[] | undefined>

  getAll(): Observable<Issue[] | undefined>

  getAllForMap(): Observable<any[] | undefined>

  getFilteredList(q: Query<IssueQuery>): Observable<IssueCategoryName[] | undefined>

  add(e: IssueDTO): Observable<IssueDTO | undefined>

  update(e: IssueDTO): Observable<IssueDTO | undefined>

  delete(id: string): Observable<boolean>

  history(issueId: string): Observable<IssueHistory[] | undefined>

  worknotes(issueId: string): Observable<Worknote[] | undefined>

  getDashBoardData(): Observable<any[] | undefined>

  getIssueCount(q: Query<IssueQuery>): Observable<number>

  generateReport(id: string): Observable<FileDTO>

  generateReports(ids: string[]): Observable<FileDTO>

  getCategories(): Observable<Category[]>

  downloadExcel(q: Query<IssueQuery>): Observable<F | undefined>
}

export class IssueService implements IIssueService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IIssueApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IIssueApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: IssueDTO): Observable<IssueDTO | undefined> {
    return this._api.add(e)
  }

  downloadExcel(q: Query<IssueQuery>): Observable<F | undefined> {
    return this._api.downloadExcel(q)
  }

  getByID(id: string): Observable<Issue | undefined> {
    return this._api.getByID(id).pipe(
      filter(isNonUndefined),
      mergeMap((d) => this.buildIssue(d))
    )
  }

  getByWorkerID(id: string): Observable<Issue[] | undefined> {
    return this._api.getByWorkerID(id).pipe(switchMap((dtos) => this.fetchFullIssue(dtos)))
  }

  update(e: IssueDTO): Observable<IssueDTO | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  buildIssue(d: IssueDTO) {
    return forkJoin({
      state: this.loadState(d.stateID),
      type: this.loadIssueType(d.typeID),
      user: this.loadUser(d.userID, d.id),
      address: this.loadAddress(d.addressID),
    }).pipe(
      map((res: any) => {
        return new Issue({
          ...d,
          state: res.state,
          address: res.address,
          user: res.user,
          responsible: res.responsible,
          type: res.type,
        })
      })
    )
  }

  buildIssue2(d: IssueCategoryName) {
    return forkJoin({
      state: this.loadState(d.stateID),
      type: this.loadIssueType(d.typeID),
      user: this.loadUser(d.userID, d.id),
      address: this.loadAddress(d.addressID),
    }).pipe(
      map((res: any) => {
        let aux: IssueCategoryName = {
          ...d,
          state: res.state,
          address: res.address,
          user: res.user,
          type: res.type,
        }

        return aux
      })
    )
  }

  fetchFullIssue2(data: IssueCategoryName[]): Observable<IssueCategoryName[]> {
    return from(data).pipe(
      mergeMap((d) => this.buildIssue2(d)),
      toArray()
    )
  }

  fetchFullIssue(data: IssueDTO[]): Observable<Issue[]> {
    return from(data).pipe(
      mergeMap((d) => this.buildIssue(d)),
      toArray()
    )
  }

  getAll(): Observable<any[] | undefined> {
    return this._api.getAll().pipe(switchMap((dtos) => this.fetchFullIssue(dtos)))
  }

  getAllForMap(): Observable<any[] | undefined> {
    return this._api.getAll()
  }

  history(issueId: string): Observable<IssueHistory[] | undefined> {
    return of(HISTORY[issueId])
  }

  worknotes(issueId: string): Observable<Worknote[] | undefined> {
    return of(WORKNOTES[issueId])
  }

  loadIssueType(typeId: string): Observable<IssueType> {
    return getIssueContainer().get<IIssueTypeService>(ISSUETYPE_SERVICE_KEY).getByID(typeId)
  }

  loadUser(userId: string, issueId: string): Observable<User | undefined> {
    if (userId.length < 40) {
      getIssueContainer()
        .get<IIssueHistoryService>(ISSUEHISTORY_SERVICE_KEY)
        .getByIssueID(issueId)
        .pipe(map((h) => {}))
    }
    return getUserContainer().get<UserService>(USER_SERVICE_KEY).getByID(userId)
  }

  loadState(stateId: string): Observable<State> {
    return getIssueContainer().get<IStateService>(STATE_SERVICE_KEY).getByID(stateId)
  }

  loadAddress(addressId: string): Observable<Address> {
    return getIssueContainer().get<IAddressService>(ADDRESS_SERVICE_KEY).getByID(addressId)
  }

  getFilteredList(q: Query<IssueQuery>): Observable<IssueCategoryName[] | undefined> {
    return this._api.getFilteredList(q).pipe(switchMap((dtos) => this.fetchFullIssue2(dtos)))
  }

  getDashBoardData(): Observable<any[] | undefined> {
    return this._api.getDashBoardData()
  }

  getIssueCount(q: Query<IssueQuery>): Observable<number> {
    return this._api.getIssueCount(q)
  }

  generateReport(id: string): Observable<FileDTO> {
    return this._api.generateReport(id)
  }

  generateReports(ids: string[]): Observable<FileDTO> {
    return this._api.generateReports(ids)
  }

  getCategories(): Observable<Category[]> {
    return this._api.getCategories()
  }
}
