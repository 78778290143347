import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'whatwg-fetch'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { prepareModules } from './modules'
import './i18n'
import './config/init-dayjs'
import { taskManagerTheme } from './common/utils/styles'
import { MuiThemeProvider } from '@material-ui/core'
import DayJsUtils from '@date-io/dayjs'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { BrowserRouter } from 'react-router-dom'

prepareModules()

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={taskManagerTheme}>
      <MuiPickersUtilsProvider utils={DayJsUtils} locale={navigator.language.split('-')[0]}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
