import { ROUTE_LOGIN } from '../../routes/routes-constants'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { Avatar, Box, Divider, Grid, Stack, Toolbar } from '@mui/material'
import iconLogout from '../../assets/superior_menu/icon-logout.svg'
import { LoggedUser } from '../../modules/users/models/LoggedUser'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery, IconButton, Drawer } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import React, { useState } from 'react'
import { Close } from '@mui/icons-material'
import ListItems from './ListItems'
import styles from '../header/Header.module.css'
import logo from '../../assets/left_menu/logo.svg'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

type CustomNavRightProps = {
  handleChangeView: (view: string) => void
}

const NavRight = (props: CustomNavRightProps) => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width:767.98px)')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleLogout = () => {
    loggedUserService.logout()
    navigate(ROUTE_LOGIN)
  }

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
    }
  }

  const handleChangeView = (st: string) => {
    props.handleChangeView(st)
    setIsMenuOpen(!isMenuOpen)
  }

  function renderUserInfo(user: LoggedUser) {
    if (user && user.name) {
      return (
        <>
          <Grid
            container
            justifyContent={isMobile ? 'space-between' : 'flex-end'}
            alignItems="center"
          >
            {isMobile && (
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>
            )}
            <Stack direction={'row'} spacing={2} style={{ marginRight: 10 }}>
              <Box style={{ textAlign: 'right' }}>
                <Box style={{ fontWeight: 'bold' }}>{user?.name}</Box>
                <Box>{user?.email}</Box>
              </Box>
              <Avatar {...stringAvatar(user.name)} />
              <img style={{ cursor: 'pointer' }} src={iconLogout} onClick={() => handleLogout()} />
            </Stack>
          </Grid>
          <Drawer
            anchor="left"
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          >
            <Box
              sx={{
                width: '65vw',
                backgroundColor: 'var(--color-drawer)',
                paddingTop: '80px',
                height: '100%',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={() => setIsMenuOpen(false)} color="inherit">
                  <Close style={{ color: 'white' }} />
                </IconButton>
              </Box>
              <Toolbar
                sx={{
                  px: [1],
                }}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <img className={styles.drawerLogoMobile} src={logo} />
              </Toolbar>
              <Divider />
              <ListItems
                handleClick={(st) => handleChangeView(st)}
                isOpen={isMenuOpen}
                setOpen={setIsMenuOpen}
              />
            </Box>
          </Drawer>
        </>
      )
    }
    return <></>
  }

  let user = loggedUserService.get()
  return (user && renderUserInfo(user)) || <></>
}

export default NavRight
