import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { LoggedUserService } from '../modules/users/services/LoggedUserService'
import { getUserContainer } from '../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../modules/users'
import { Home } from '../pages/home'
import { Map } from '../pages/map'
import {
  ROUTE_HOME,
  ROUTE_ISSUES,
  ROUTE_ISSUES_EDIT,
  ROUTE_ISSUES_NEW,
  ROUTE_ISSUES_VIEW,
  ROUTE_MAP,
  ROUTE_USERS,
  ROUTE_USERS_EDIT,
  ROUTE_USERS_NEW,
  ROUTE_WORKNOTES_EDIT,
  ROUTE_WORKNOTES_NEW,
  ROUTE_WORKNOTES_VIEW,
} from './routes-constants'
import { Issues } from '../pages/issues'
import { useTranslation } from 'react-i18next'
import { IssueDetail } from '../pages/issues/IssueDetail'
import { IssueEdit } from '../pages/issues/IssueEdit'
import { WorknoteDetail } from '../pages/worknotes/WorknoteDetail'
import { WorknoteEdit } from '../pages/worknotes/WorknoteEdit'
import { Users } from '../pages/users/Users'
import { UserEdit } from '../pages/users/UserEdit'

export type RouteProps = {
  title?: string
  subtitle?: string
  id?: string
}

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const AppRouter: React.FC = () => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()

  return (
    <>
      <Routes>
        {loggedUser?.roles?.includes('user') || loggedUser?.roles?.includes('admin') ? (
          <Route path={ROUTE_HOME} element={<Home title={t('home')} />} />
        ) : null}
        <Route path={ROUTE_MAP} element={<Map title={t('map')} />} />
        <Route path={ROUTE_ISSUES} element={<Issues title={t('issues')} />} />
        <Route path={ROUTE_ISSUES_VIEW} element={<IssueDetail title={t('issue_detail')} />} />
        <Route path={ROUTE_ISSUES_EDIT} element={<IssueEdit title={t('issue_edit')} />} />
        <Route path={ROUTE_ISSUES_NEW} element={<IssueEdit title={t('issue_new')} />} />
        <Route
          path={ROUTE_WORKNOTES_VIEW}
          element={<WorknoteDetail title={t('worknote_detail')} />}
        />
        <Route path={ROUTE_WORKNOTES_EDIT} element={<WorknoteEdit title="Editar Obra" />} />
        <Route path={ROUTE_WORKNOTES_NEW} element={<WorknoteEdit title="Nueva Obra" />} />
        {loggedUser?.roles?.includes('admin') && (
          <>
            <Route path={ROUTE_USERS} element={<Users title={t('users')} />} />
            <Route path={ROUTE_USERS_EDIT} element={<UserEdit title={t('users_edit')} />} />
            <Route path={ROUTE_USERS_NEW} element={<UserEdit title={t('users_new')} />} />
          </>
        )}
      </Routes>
    </>
  )
}
