import { LoggedUser } from '../models/LoggedUser'
import { Container, IInit } from '../../../common/container/Container'
import { AuthDTO } from '../models/AuthDTO'
import { ConnectableObservable, Observable, Subject } from 'rxjs'
import { IUserApi } from '../api/UserApi'
import { IStatusService } from '../../../common/status/StatusService'
import { fromModel, LoggedUserDTO, toModel } from '../models/LoggedUserDTO'
import { multicast } from 'rxjs/operators'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UpdatePasswordDTO } from '../models/UpdatePasswordDTO'
import { UpdatePassword } from '../models/UpdatePassword'

export interface ILoggedUserService extends IInit {
  login(a: AuthDTO): Observable<LoggedUserDTO | undefined>
  
  logout(): void

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined>

  passwordRecover(email: string): Observable<boolean>

  getObservable(): Observable<LoggedUser | undefined>

  get(): LoggedUser | undefined

  setRoles(roles: string[], permissions: string[]): void
  
  userCan(can: string): boolean
}

const LOGGED_USER_KEY = 'logged user'
export const ISSUE_FILTERS = 'issueFilters'

type Props = {
  apiKey: symbol
}

export class LoggedUserService implements ILoggedUserService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  private _user: LoggedUser | undefined
  private _userSubject = new Subject<LoggedUser>()

  constructor(p: Props) {
    this.loadUser()
    this._apiKey = p.apiKey
    this._user = undefined
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined> {
    const multi = this._api
      .login(a)
      .pipe(multicast(() => new Subject<LoggedUserDTO | undefined>())) as ConnectableObservable<
      LoggedUserDTO | undefined
    >
    multi.connect()
    multi.subscribe((dto) => {
      if (dto) {
        this.storeUser(dto)
      }
    })

    return multi
  }

  logout() {
    this._api.logout().subscribe(() => {
      this.removeUser()
      var url = window.location
      document.location = url.protocol + '//' + url.host
      document.cookie = 'SID' + '=; Max-Age=-1; Expires=0'
    })
  }

  get(): LoggedUser | undefined {
    this._user = this.loadUser()
    return this._user
  }

  getObservable(): Observable<LoggedUser | undefined> {
    return this._userSubject.pipe()
  }

  setRoles(roles: string[], permissions: string[]) {
    if (!this._user) {
      return
    }

    this._user.roles = roles
    this._user.permissions = permissions
    this.storeUser(fromModel(this._user))
  }

  private next() {
    this._userSubject.next(this._user)
  }

  private loadUser() {
    const dto = (JSON.parse(localStorage.getItem(LOGGED_USER_KEY) || 'null') ||
      undefined) as LoggedUserDTO
    if (dto && new Date(dto.sessionExpires) > new Date()) {
      this._user = toModel(dto)

      this.next()
      setTimeout(() => this.logout(), this._user.sessionExpires.valueOf() - new Date().valueOf())
      return this._user
    }
  }

  private storeUser(dto: LoggedUserDTO) {
    localStorage.setItem(LOGGED_USER_KEY, JSON.stringify(dto))
    this._user = toModel(dto)

    this.next()
    setTimeout(() => this.logout(), this._user.sessionExpires.valueOf() - new Date().valueOf())
  }

  private removeUser() {
    localStorage.removeItem(LOGGED_USER_KEY)
    this._user = undefined
    this.next()
  }

  userCan(perm: string): boolean {
    console.log("this._user?.permissions: ", this._user?.permissions)
    return this._user?.permissions?.includes(perm) || false
  }

  passwordRecover(email: string): Observable<boolean> {
    return this._api.passwordRecover(email)
  }

  changePassword(e: UpdatePasswordDTO): Observable<UpdatePassword | undefined> {
    return this._api.changePassword(e)
  }
}
