import React, { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import GoogleMapReact, { Coords } from 'google-map-react'
import { MapFilter, MapFilters } from './MapFilters'
import { IIssueService } from '../../modules/issues'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import { IssueDTO, fromCategoryNameToIssue } from '../../modules/issues/models/Issue'
import { AddressPickerDialog, AddressPickerDialogProps } from './AddressPickerDialog'
import { ViewIssueDialog } from './ViewIssueDialog'
import { getIssueContainer } from '../../container/issue-module'
import { ROUTE_ISSUES_NEW } from '../../routes/routes-constants'
import { AddressDTO } from '../../modules/issues/models/Address'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { Query, QueryParam } from 'common/api/Query'
import { Permission } from 'common/permission'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'

export type MapProps = {} & RouteProps

const Marker = ({
  id,
  icon,
  lat,
  lng,
  onClick,
}: {
  id: string
  icon: string
  lat: number
  lng: number
  onClick: (id: string) => void
}) => (
  <img
    style={{ cursor: 'pointer' }}
    onClick={(event) => {
      event.stopPropagation()
      onClick(id)
    }}
    src={`../../assets/${icon}`}
  />
)

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Map(props: MapProps) {
  const [issues, setIssues] = useState<IssueDTO[]>([])
  const [filteredIssues, setFilteredIssues] = useState<IssueDTO[]>([])
  const [newIssue, setNewIssue] = useState<AddressPickerDialogProps>()
  const [viewIssueId, setViewIssueId] = useState<string>()
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width:767.98px)')

  useEffect(() => {
    let query: QueryParam<any>[] = []

    if (!loggedUserService.userCan(Permission.SeeAllIssues)) {
      query.push(new QueryParam('user_id', loggedUserService.get()?.id || ""))
    }

    issueService
    .getFilteredList(
      new Query({
        query: query,
      })
    )
    .subscribe((issues) => {
      issues && setIssues(issues.map((i)=> fromCategoryNameToIssue(i)))
    })
  }, [])

  useEffect(() => {
    handleFilterChange([])
  }, [issues])

  const center: Coords = { lat: 37.984047, lng: -1.128575 }
  const zoom: number = 13

  function handleFilterChange(filters: MapFilter[]) {
    let statesids = filters.filter((s) => s.active).map((s) => s.id)

    if (statesids.length > 0) {
      //@ts-ignore
      let filtered = issues.filter((s) => statesids.includes(s.stateID))
      setFilteredIssues(filtered)
    } else {
      setFilteredIssues(issues)
    }
  }

  function createIssue(address: AddressDTO | undefined, lat: number, lng: number) {
    const issue: IssueDTO = {
      description: '',
      stateID: '',
      typeID: '',
      userID: '',
      workflowID: '',
      createdAt: new Date(),
      id: '',
      addressID: '',
      lat: lat,
      lng: lng,
      incNumber: 0,
      phone: '',
      email: '',
      category_id: '',
    }
    navigate(ROUTE_ISSUES_NEW, { state: { issue: issue, address: address } })
  }

  function viewIssue(id: string) {
    setViewIssueId(id)
  }

  const getIcon = (issue: any) => {
    switch (issue.stateID) {
      case '1':
        return 'state01.svg'
      case '2':
        return 'state02.svg'
      case '3':
        return 'state03.svg'
      case '4':
        return 'state05.svg'
      case '5':
        return 'state06.svg'
      case '6':
        return 'state04.svg'
      case '7':
        return 'state06.svg'
      case '8':
        return 'state07.svg'
      default:
        return ''
    }
  }

  function getMarkers() {
    return filteredIssues.map((issue) => (
      <Marker
        onClick={() => viewIssue(issue.id)}
        key={issue.id}
        id={issue.id}
        lat={issue.lat}
        lng={issue.lng}
        icon={getIcon(issue)}
      />
    ))
  }

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 0,
        width: isMobile ? '100%' : '85%',
        height: 'calc(100% - 75px)',
        padding: 0,
      }}
    >
      <div
        style={{
          position: 'absolute',
          zIndex: 0,
          width: '100%',
          height: '100%',
          padding: 0,
        }}
      >
        {/*@ts-ignore*/}
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY || '' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          {getMarkers()}
        </GoogleMapReact>
      </div>
      <div
        style={{
          zIndex: 1,
          position: 'absolute',
          top: 30,
          left: 10,
        }}
      >
        <MapFilters onFilterChange={handleFilterChange} />
        {newIssue && (
          <AddressPickerDialog
            onClose={() => setNewIssue(undefined)}
            open={newIssue.open}
            lat={newIssue.lat}
            title={'Nueva Incidencia'}
            lng={newIssue.lng}
            district={newIssue.district}
            successLabel={'CREAR'}
            onSuccess={(address, lat, lng) => createIssue(address, lat, lng)}
          />
        )}
        {viewIssueId && (
          <ViewIssueDialog
            onClose={() => setViewIssueId(undefined)}
            open={true}
            issueId={viewIssueId}
          />
        )}
      </div>
    </div>
  )
}
