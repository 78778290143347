import DividerTheme from '../../components/divider/DividerTheme'
import DataTable from '../../components/table/DataTable'
import iconAddGrey from '../../assets/add-grey.svg'
import { Grid } from '@mui/material'
import React, { SetStateAction, useEffect, useState } from 'react'
import { ROUTE_WORKNOTES, ROUTE_WORKNOTES_EDIT } from '../../routes/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Worknote } from '../../modules/worknotes/models/Worknote'
import dayjs from 'dayjs'
import { getIssueContainer } from '../../container/issue-module'
import { Query, QueryParam } from '../../common/api/Query'
import { IWorkService } from 'modules/issues/services/WorkService'
import { WORK_SERVICE_KEY } from 'modules/issues/container'
import { Work, WorkDTO } from 'modules/issues/models/Work'

const workService = getIssueContainer().get<IWorkService>(WORK_SERVICE_KEY)

interface WorknotesTableProps {
  issueID: string
}

interface WorkView extends WorkDTO {
  updated: string
}

const worknoteColumns = [
  { id: 'description', name: 'Descripción', size: '40%' },
  { id: 'updated', name: 'Actualización', size: '15%' },
  { id: 'state', name: 'Estado', size: '35%' },
]

function workToView(w: Work): WorkView {
  const wn = {
    id: w.id,
    description: w.description,
    image1ID: w.image1ID,
    image2ID: w.image2ID,
    issueID: w.issueID,
    state: w.state,
    createdAt: w.createdAt,
    updatedAt: w.updatedAt,
    updated: dayjs(w.updatedAt).format('DD/MM/YYYY'),
  }
  return wn
}

const INIT_PAGE = 0
const DEFAULT_ROWS_PER_PAGE = 5

const updateWorks = (
  id: string,
  page: number,
  rowsPerPage: number,
  setWorks: React.Dispatch<SetStateAction<WorkView[]>>
) => {
  workService
    .getFilteredList(
      new Query({
        pager: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
        },
        query: [new QueryParam('issueID', id || '')],
      })
    )
    .subscribe((res) => {
      res && setWorks(res.items.map(workToView))
    })
}

export function WorknotesTable({ issueID }: WorknotesTableProps) {
  const navigate = useNavigate()
  const [worknoteCount, setWorknoteCount] = useState(-1)
  const [currentPage, setCurrentPage] = useState(INIT_PAGE)
  const [works, setWorks] = useState<WorkView[]>([])

  useEffect(() => {
    workService.getCountByID(issueID).subscribe((count) => setWorknoteCount(count))
    updateWorks(issueID, INIT_PAGE, DEFAULT_ROWS_PER_PAGE, setWorks)
  }, [])

  useEffect(() => {
    updateWorks(issueID, currentPage, DEFAULT_ROWS_PER_PAGE, setWorks)
  }, [currentPage])

  function addWorknote(id: string) {
    navigate(`${ROUTE_WORKNOTES_EDIT}/${id}`, { state: { id: id } })
  }

  function editWorknote(row: Worknote) {
    navigate(`${ROUTE_WORKNOTES}/${row.id}`, { state: { id: row.id } })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage)
  }

  return (
    <Grid item xs={6} mb={5}>
      <DividerTheme
        action={{
          icon: iconAddGrey,
          iconHeight: 20,
          iconWidth: 20,
          onClick: () => addWorknote(issueID),
        }}
        fontSize={17}
        fontColor={'var(--color-orange-theme)'}
        title={'Obras'}
      />
      <DataTable
        onRowClick={(row) => editWorknote(row)}
        data={works}
        columns={worknoteColumns}
        totalRows={worknoteCount}
        currentPage={currentPage}
        rowsPerPage={DEFAULT_ROWS_PER_PAGE}
        rowsPerPageOptions={[]}
        handleChangePage={handleChangePage}
        entity="worknote"
      />
    </Grid>
  )
}
