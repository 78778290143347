import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { Toolbar, Divider } from '@mui/material'
import ListItems from './ListItems'
import styles from '../header/Header.module.css'
import logo from '../../assets/left_menu/logo.svg'

type CustomDrawerProps = {
  open: boolean
  toggleDrawer: () => void
  handleChangeView: (view: string) => void
  setOpen: (b: boolean) => void
}

const drawerWidth: string = '15%'

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: 'var(--color-drawer)',
    padding: '8px 8px 0px',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(11),
      },
    }),
  },
}))

const CustomDrawer = (props: CustomDrawerProps) => {
  const handleClick = (type: string) => {
    props.handleChangeView(type)
  }

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          '@media (max-width: 767.98px)': {
            display: 'none',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar
          className="toolBar"
          sx={{
            px: [1],
          }}
        >
          <div className={styles.drawerBox}>
            <img className={styles.drawerLogo} src={logo} />
          </div>
        </Toolbar>
        <Divider />

        <ListItems handleClick={handleClick} isOpen={props.open} setOpen={props.setOpen} />
      </Drawer>
    </>
  )
}

export default CustomDrawer
