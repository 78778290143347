import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import iconMap from '../../assets/left_menu/icon-map.svg'
import iconIssues from '../../assets/left_menu/icon-issues.svg'
import iconAdmin from '../../assets/left_menu/icon-admin.svg'
import iconDashboard from '../../assets/left_menu/icon-dashboard.svg'
import { makeStyles } from '@material-ui/core'
import styles from '../header/Header.module.css'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { ROUTE_HOME, ROUTE_ISSUES, ROUTE_MAP, ROUTE_USERS } from '../../routes/routes-constants'

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

type ListItemsProps = {
  handleClick: (type: string) => void
  isOpen: boolean
  setOpen: (b: boolean) => void
}

const ListItems = (props: ListItemsProps) => {
  const { t } = useTranslation()
  const pathname = window.location.pathname
  const useStyles = makeStyles({
    item: {
      padding: 50,
    },
  })

  const classes = useStyles()

  const changeStyleIcon = (path: string) => {
    if (path === pathname) {
      return styles.iconActive
    } else if (path === ROUTE_ISSUES && pathname?.includes(ROUTE_ISSUES)) {
      return styles.iconActive
    }
    if (path === ROUTE_HOME) return styles.filterOrangeTheme
    if (path === ROUTE_ISSUES) return styles.filterRedTheme
    if (path === ROUTE_MAP) return styles.filterBlueTheme
    if (path === ROUTE_USERS) return styles.filterGrayTheme
  }

  const changeBackground = (path: string) => {
    if (path === pathname) {
      return styles.iconContainerActive
    } else if (path === ROUTE_ISSUES && pathname?.includes(ROUTE_ISSUES)) {
      return styles.iconContainerActive
    }
    return styles.iconContainer
  }

  const loggedUser = loggedUserService.get()

  return (
    <List style={{ marginTop: 40 }}>
      {loggedUser?.roles?.includes('user') || loggedUser?.roles?.includes('admin') ? (
        <ListItem
          classes={{ root: classes.item }}
          className={changeBackground(ROUTE_HOME)}
          button
          style={{ paddingTop: 20, marginTop: props.isOpen ? '0%' : '13%' }}
          onClick={() => {
            props.handleClick('DASHBOARD')
          }}
        >
          <ListItemIcon>
            <img className={changeStyleIcon(ROUTE_HOME)} src={iconDashboard} />
          </ListItemIcon>
          <ListItemText className={styles.textMenu} primary={t('dashboard')} />
        </ListItem>
      ) : null}
      <ListItem
        classes={{ root: classes.item }}
        className={changeBackground(ROUTE_ISSUES)}
        button
        onClick={() => props.handleClick('ISSUES')}
      >
        <ListItemIcon>
          <img className={changeStyleIcon(ROUTE_ISSUES)} src={iconIssues} />
        </ListItemIcon>
        <ListItemText className={styles.textMenu} primary={t('issues')} />
      </ListItem>
      <ListItem
        classes={{ root: classes.item }}
        className={changeBackground(ROUTE_MAP)}
        button
        onClick={() => {
          props.handleClick('MAP')
        }}
      >
        <ListItemIcon>
          <img className={changeStyleIcon(ROUTE_MAP)} src={iconMap} />
        </ListItemIcon>
        <ListItemText className={styles.textMenu} primary={t('map')} />
      </ListItem>
      {loggedUser?.roles?.includes('admin') && (
        <ListItem
          classes={{ root: classes.item }}
          className={changeBackground(ROUTE_USERS)}
          button
          onClick={() => props.handleClick('USERS')}
        >
          <ListItemIcon>
            <img className={changeStyleIcon(ROUTE_USERS)} src={iconAdmin} />
          </ListItemIcon>
          <ListItemText className={styles.textMenu} primary={t('users')} />
        </ListItem>
      )}
    </List>
  )
}

export default ListItems
