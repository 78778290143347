import React, { SetStateAction, useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Avatar, Box, Menu, MenuItem, Stack, Button } from '@mui/material'
import { Filter, IssuesFilter } from './IssuesFilter'
import DataTable from '../../components/table/DataTable'
import { ROUTE_ISSUES, ROUTE_ISSUES_NEW } from '../../routes/routes-constants'
import DividerTheme, { DividerAction } from '../../components/divider/DividerTheme'
import iconAdd from '../../assets/add-grey.svg'
import iconAvatar from '../../assets/images/avatar-2.jpg'
import { useNavigate } from 'react-router-dom'
import { getIssueContainer } from '../../container/issue-module'
import { IIssueService } from '../../modules/issues'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import { IssueCategoryName } from '../../modules/issues/models/Issue'
import StateButton from '../../components/StateButton'
import dayjs from 'dayjs'
import iconMore from '../../assets/table/icon-more.svg'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService, ISSUE_FILTERS } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { Query, QueryParam } from '../../common/api/Query'
import { downloadFile } from 'common/utils/file'
import CircularProgress from '@mui/material/CircularProgress'
import { useMediaQuery } from '@mui/material'
import { Permission } from 'common/permission'

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

interface IssueView extends IssueCategoryName {
  stateView: JSX.Element
  userView: JSX.Element
  actionsView: JSX.Element
  districtView: string
  typeView: string
  addressView: string
  dateView: string
}

function RowActions({ id }: { id: string }) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const loggedUser = loggedUserService.get()

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function showIssue() {
    navigate(`${ROUTE_ISSUES}/${id}`, { state: { id: id } })
  }

  function editIssue() {
    navigate(`${ROUTE_ISSUES}/edit/${id}`, { state: { id: id } })
  }

  function removeIssue() {
    issueService.delete(id).subscribe(() => {
      window.location.reload()
    })
  }

  return (
    <div>
      <img
        style={{ cursor: 'pointer' }}
        aria-controls={open ? 'basic-menu' : undefined}
        id="basic-button"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => handleClick(event)}
        src={iconMore}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => showIssue()}>Ver</MenuItem>
        <MenuItem disabled={loggedUser?.roles.includes('worker')} onClick={editIssue}>
          Editar
        </MenuItem>
        <MenuItem disabled={loggedUser?.roles.includes('worker')} onClick={removeIssue}>
          Eliminar
        </MenuItem>
      </Menu>
    </div>
  )
}

function issueToView(i: IssueCategoryName): IssueView {
  return {
    ...i,
    dateView: dayjs(i.createdAt).format('DD/MM/YYYY'),
    stateView: <StateButton state={i?.state} />,
    districtView: i.address.district,
    typeView: i.type.name,
    addressView: i.address.address,
    userView: (
      <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
        {i?.user?.firstName ? (
          <>
            <Avatar alt={i?.user?.firstName} src={iconAvatar} />
            <label>{`${i?.user?.firstName} ${i?.user?.lastName}`}</label>
          </>
        ) : (
          <>
            <Avatar alt={i.email} src={iconAvatar} />
            <label>{i.email}</label>
          </>
        )}
      </Stack>
    ),
    actionsView: <RowActions id={i.id} />,
  }
}

const updateIssues = (
  page: number,
  rowsPerPage: number,
  setIssues: React.Dispatch<SetStateAction<IssueCategoryName[]>>,
  since: Date | undefined,
  until: Date | undefined,
  setIssueCount: React.Dispatch<SetStateAction<number>>,
  state: string,
  expedient: string,
  district: string,
  location: string
) => {
  let query: QueryParam<any>[] = []

  if (!loggedUserService.userCan(Permission.SeeAllIssues)) {
    query.push(new QueryParam('user_id', loggedUserService.get()?.id || ""))
  }

  if (since) {
    query.push(new QueryParam('since', since?.toLocaleDateString()))
  }

  if (until) {
    until.setDate(until.getDate() + 1)
    query.push(new QueryParam('until', until.toLocaleDateString()))
  }

  if (state) {
    query.push(new QueryParam('state_id', state))
  }

  if (expedient) {
    query.push(new QueryParam('expedient', expedient))
  }

  if (district) {
    query.push(new QueryParam('district', district))
  }

  if (location) {
    query.push(new QueryParam('location', location))
  }

  issueService
    .getFilteredList(
      new Query({
        pager: {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
        },
        query: query,
      })
    )
    .subscribe((issues) => {
      issueService
        .getIssueCount(
          new Query({
            query: query,
          })
        )
        .subscribe((count) => {
          setIssueCount(count)
        })

      if (issues) {
        issues.sort((a, b) => Number(b.incNumber) - Number(a.incNumber))
        setIssues(issues)
      }
    })
}

const getRowsPerPageOptions = (rows: number): number[] => {
  const options = [10, 25, 50, 100]
  return options.filter((n) => n <= rows)
}

export function Issues(props: RouteProps) {
  const INIT_PAGE = 0
  const DEFAULT_ROWS_PER_PAGE = 10

  const title = props.title || ''
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [issues, setIssues] = useState<IssueView[]>([])
  const [issueCount, setIssueCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(INIT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE)
  const [rawIssues, setRawIssues] = useState<IssueCategoryName[]>([])
  const [filteredIssues, setFilteredIssues] = useState<IssueCategoryName[]>([])
  const [filter, setFilter] = useState<Filter>()
  const [since, setSince] = useState<Date>()
  const [until, setUntil] = useState<Date>()
  const [state, setState] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [expedient, setExpedient] = useState<string>('')
  const [district, setDistrict] = useState('')
  const [downloading, setDownloading] = useState(false)
  const [checked, setChecked] = useState<any[]>([])
  const isMobile = useMediaQuery('(max-width:767.98px)')

  const handleCheck = (id: string) => {
    const isSame = (item: any) => item.id === id
    let item = checked.findIndex(isSame)

    if (item !== -1) {
      let aux = [...checked]
      aux[item].checked = !aux[item].checked
      setChecked(aux)
    } else {
      let aux = [...checked]
      aux.push({ id: id, checked: true })
      setChecked(aux)
    }
  }

  function isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0
  }

  useEffect(() => {
    let f = localStorage.getItem(ISSUE_FILTERS)
    if (f === undefined || f === null || f === '{}') {
      issueService.getIssueCount(new Query({})).subscribe((count) => setIssueCount(count))
      updateIssues(
        currentPage,
        rowsPerPage,
        setRawIssues,
        filter?.since ? new Date(filter.since) : undefined,
        filter?.until ? new Date(filter.until) : undefined,
        setIssueCount,
        filter?.state && filter?.state !== '-1' ? filter.state : '',
        filter?.expedient ? filter.expedient : '',
        filter?.district ? filter.district : '',
        filter?.location ? filter.location : ''
      )
    }
  }, [currentPage, rowsPerPage])

  useEffect(() => {
    rawIssues && setFilteredIssues(rawIssues)
  }, [rawIssues])

  useEffect(() => {
    filteredIssues && setIssues(filteredIssues.map((i) => issueToView(i)))
  }, [filteredIssues])

  useEffect(() => {
    if (filter === undefined) return
    if (isEmptyObject(filter)) return
    updateIssues(
      currentPage,
      rowsPerPage,
      setRawIssues,
      filter?.since ? new Date(filter.since) : undefined,
      filter?.until ? new Date(filter.until) : undefined,
      setIssueCount,
      filter?.state && filter?.state !== '-1' ? filter.state : '',
      filter?.expedient ? filter.expedient : '',
      filter?.district ? filter.district : '',
      filter?.location ? filter.location : ''
    )

    filter?.district ? setDistrict(filter.district) : setDistrict('')

    filter?.state && filter?.state !== '-1' ? setState(filter.state) : setState('')
    //@ts-ignore
    filter?.since ? setSince(new Date(filter.since)) : setSince(undefined)
    //@ts-ignore
    filter?.until ? setUntil(new Date(filter.until)) : setUntil(undefined)

    filter?.location ? setLocation(filter.location) : setLocation('')

    filter?.expedient ? setExpedient(filter.expedient) : setExpedient('')

    localStorage.setItem(ISSUE_FILTERS, JSON.stringify(filter))
  }, [filter, currentPage, rowsPerPage])

  const columns = [
    { id: 'incNumber', name: 'Expediente', size: '10%' },
    { id: 'typeView', name: 'Tipo', size: '10%' },
    { id: 'category_name', name: 'Procedencia', size: '10%' },
    { id: 'addressView', name: 'Ubicación', size: '15%' },
    { id: 'dateView', name: 'Fecha de alta', size: '10%' },
    { id: 'stateView', name: 'Estado', size: '20%' },
    { id: 'actionsView', name: '', size: '5%' },
  ]

  function handleFilter(filter: Filter) {
    setFilter(filter)
  }

  function handleClick(row: any) {
    navigate(`${ROUTE_ISSUES}/${row.id}`, { state: { id: row.id } })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage)
  }

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newValue = parseInt(event.target.value)
    if (!isNaN(newValue)) {
      setRowsPerPage(newValue)
      setCurrentPage(INIT_PAGE)
    }
  }

  let headerAction: DividerAction = {
    icon: iconAdd,
    onClick: () => {
      navigate(ROUTE_ISSUES_NEW)
    },
  }

  const handleDownloadReports = async () => {
    setDownloading(true)
    let cks = checked.filter((items) => items.checked === true).map((i) => i.id)

    issueService.generateReports(cks).subscribe((res) => {
      res && downloadFile('Reports-' + new Date().toLocaleDateString() + '.pdf', 'pdf', res.base64)
      setDownloading(false)
      setChecked([])
    })
  }

  const handleDownloadPDF = () => {
    let query: QueryParam<any>[] = []

    if (!loggedUserService.userCan(Permission.SeeAllIssues)) {
      query.push(new QueryParam('user_id', loggedUserService.get()?.id || ""))
    }
  
    if (since) {
      query.push(new QueryParam('since', since?.toLocaleDateString()))
    }

    if (until) {
      query.push(new QueryParam('until', until?.toLocaleDateString()))
    }

    if (state) {
      query.push(new QueryParam('state_id', state))
    }

    if (expedient) {
      query.push(new QueryParam('expedient', expedient))
    }

    if (district) {
      query.push(new QueryParam('district', district))
    }

    if (location) {
      query.push(new QueryParam('location', location))
    }

    setDownloading(true)
    issueService
      .downloadExcel(
        new Query({
          query: query,
        })
      )
      .subscribe((res) => {
        setDownloading(false)
        res && downloadFile('Issues-' + new Date().toLocaleDateString() + '.xls', 'xls', res.base64)
      })
  }

  const handleClearFilters = () => {
    localStorage.setItem(ISSUE_FILTERS, '')
    setDistrict('')
    setState('')
    //@ts-ignore
    setSince(undefined)
    //@ts-ignore
    setUntil(undefined)
    setLocation('')
    setExpedient('')
    updateIssues(
      currentPage,
      rowsPerPage,
      setRawIssues,
      undefined,
      undefined,
      setIssueCount,
      '',
      '',
      '',
      ''
    )
  }

  return (
    <div>
      <Box style={{ padding: isMobile ? 25 : 24 }}>
        <DividerTheme
          title={title}
          action={!loggedUser?.roles.includes('worker') ? headerAction : undefined}
        />
        <Box style={{ width: '100%', marginTop: 20 }}>
          <IssuesFilter
            onFilterChange={(filter) => handleFilter(filter)}
            issues={rawIssues}
            handleClearFilters={handleClearFilters}
          />
        </Box>

        <Box
          style={{
            width: '100%',
            marginTop: 35,
            justifyContent: 'flex-end',
            display: 'flex',
            flex: 1,
          }}
        >
          {!downloading ? (
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#13945c',
                  marginRight: '2%',
                  fontSize: isMobile ? 11.5 : 14,
                }}
                onClick={handleDownloadReports}
                disabled={checked?.filter((items) => items.checked === true)?.length === 0}
              >
                Descargar Informes
              </Button>

              <Button
                variant="contained"
                onClick={handleDownloadPDF}
                style={{
                  backgroundColor: '#E80032',
                  fontSize: isMobile ? 11.5 : 14,
                }}
              >
                Descargar Excel
              </Button>
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>

        <Box style={{ marginTop: 30 }}>
          <DataTable
            withActions={false}
            onRowClick={(row) => handleClick(row)}
            data={issues}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions(issueCount)}
            totalRows={issueCount}
            columns={columns}
            handleChangePage={handleChangePage}
            handleRowsPerPageChange={handleRowsPerPageChange}
            entity="issue"
            handleCheck={handleCheck}
            checked={checked}
          />
        </Box>
      </Box>
    </div>
  )
}
