import { useState, useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { Issue } from '../../modules/issues/models/Issue'
import BoxTheme from '../../components/box/BoxTheme'
import IssuesMap from '../../components/map/IssuesMap'
import { Coords } from 'google-map-react'
import { useParams } from 'react-router-dom'
import DividerTheme from '../../components/divider/DividerTheme'
import { IIssueService } from '../../modules/issues'
import { ISSUE_SERVICE_KEY } from '../../modules/issues/container'
import CustomButton from '../../components/form/CustomButton'
import PDFViewer from '../../components/PDFViewer'
import StateButton from '../../components/StateButton'
import { getIssueContainer } from '../../container/issue-module'
import { FileDTO, File as ThisFile } from '../../modules/files/models/File'
import { FILE_SERVICE_KEY } from 'modules/files/container'
import { FileService } from 'modules/files/services/FileService'
import { Query, QueryParam } from 'common/api/Query'
import { ActionsTable } from './ActionsTable'
import { WorknotesTable } from './WorknotesTable'
import { useMediaQuery } from '@mui/material'

export type IssueDetailProps = {
  issue?: Issue
} & RouteProps

const issueService = getIssueContainer().get<IIssueService>(ISSUE_SERVICE_KEY)
const fileService = getIssueContainer().get<FileService>(FILE_SERVICE_KEY)

export function IssueDetail(props: IssueDetailProps) {
  const title = props.title || ''
  const { id } = useParams()
  const [showReport, setShowReport] = useState<boolean>(false)
  const [issue, setIssue] = useState<Issue>()
  const [report, setReport] = useState<string>()
  const [images, setImages] = useState<FileDTO[]>([])
  const [executionImages, setExecutionImages] = useState<ThisFile[]>([])
  const [resolutionImages, setResolutionImages] = useState<ThisFile[]>([])
  const isMobile = useMediaQuery('(max-width:767.98px)')

  useEffect(() => {
    if (!issue) return

    fileService
      .getFilteredList(
        new Query({
          query: [new QueryParam('issueID', id || '')],
        })
      )
      .subscribe((res) => {
        setImages(
          res.items
            .filter((i) => i.type === 'image')
            .map((img: any) => {
              img.base64 = `data:image/${img.extension};base64,` + img.base64
              return img
            })
        )
        setResolutionImages(
          res.items
            .filter((i) => i.type === 'resolutionImage')
            .map((img: any) => {
              img.base64 = `data:image/${img.extension};base64,` + img.base64
              return img
            })
        )
        setExecutionImages(
          res.items
            .filter((i) => i.type === 'executionImage')
            .map((img: any) => {
              img.base64 = `data:image/${img.extension};base64,` + img.base64
              return img
            })
        )
      })
  }, [issue])

  useEffect(() => {
    if (id) {
      issueService.getByID(id).subscribe((i) => i && setIssue(i))
    }
  }, [id])

  function downloadPDF() {
    const element = document.createElement('a')
    element.href = report || ''
    element.download = 'report_' + issue?.incNumber + '.pdf'

    document.body.appendChild(element)
    element.click()
    element.remove()
  }

  const handleShowReport = () => {
    if (id) {
      issueService.generateReport(id).subscribe(async (res) => {
        const base64File = `data:${res.type}/${res.extension};base64,${res.base64}`
        const blob = await fetch(base64File).then((data) => data.blob())
        setReport(URL.createObjectURL(blob))
        setShowReport(true)
      })
    }
  }

  const renderImageOrDefaultText = (image: string | undefined) => {
    return image ? (
      <Box
        style={{ width: '100%', border: 'solid 0.1px #c9c9c9' }}
        component="img"
        src={image}
      ></Box>
    ) : (
      <Box style={{ width: '100%' }}>{'Sin Imagen'}</Box>
    )
  }

  const center: Coords = (issue && { lat: issue.lat, lng: issue.lng }) || {
    lat: 37.60512,
    lng: -0.98623,
  }

  const zoom: number = 16

  return (
    <div>
      <Box style={{ padding: isMobile ? 25 : 24 }}>
        <DividerTheme title={title} />
        {showReport && (
          <Dialog
            open={showReport}
            fullWidth={true}
            maxWidth={'md'}
            onClose={() => setShowReport(false)}
          >
            <DialogTitle id="scroll-dialog-title">Informe Incidencia</DialogTitle>
            <DialogActions>
              <CustomButton color={'primary'} onClick={() => downloadPDF()} fullWidth={true}>
                {'DESCARGAR'}
              </CustomButton>
            </DialogActions>
            <DialogContent>
              <PDFViewer file={report || ''} />
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={() => setShowReport(false)} fullWidth={true}>
                {'CERRAR'}
              </CustomButton>
            </DialogActions>
          </Dialog>
        )}
        <Box style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'ID'}>{issue?.incNumber}</BoxTheme>
                </Grid>
                <Grid item xs={6} mb={5}></Grid>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Tipo'}>{issue?.type.name}</BoxTheme>
                </Grid>
                <Grid item xs={6} mb={5}>
                  <BoxTheme title={'Estado'}>
                    <StateButton state={issue?.state} />
                  </BoxTheme>
                </Grid>
                <Grid item xs={12} mb={5}>
                  <BoxTheme title={'Descripción'}>{issue?.description}</BoxTheme>
                </Grid>
                <Grid item xs={8} mb={5}>
                  <BoxTheme title={'Email'}>
                    <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
                      <Typography variant={'h6'}>{issue?.email}</Typography>
                    </Stack>
                  </BoxTheme>
                </Grid>
                <Grid item xs={4} mb={5}>
                  <BoxTheme title={'Teléfono'}>
                    <Stack direction={'row'} spacing={2} style={{ alignItems: 'center' }}>
                      <Typography variant={'h6'}>{issue?.phone}</Typography>
                    </Stack>
                  </BoxTheme>
                </Grid>
                <Grid item xs={4} mb={5}>
                  <BoxTheme title={'Imagen Incidencia'}>
                    {images.map((im) => (
                      <div style={{ marginTop: '5%' }}>{renderImageOrDefaultText(im?.base64)}</div>
                    ))}
                  </BoxTheme>
                </Grid>
                <Grid item xs={4} mb={5}>
                  <BoxTheme title={'Imagen Ejecución'}>
                    {executionImages.map((im) => (
                      <div style={{ marginTop: '5%' }}>{renderImageOrDefaultText(im?.base64)}</div>
                    ))}
                  </BoxTheme>
                </Grid>
                <Grid item xs={4} mb={5}>
                  <BoxTheme title={'Imagen Resolución'}>
                    {resolutionImages.map((im) => (
                      <div style={{ marginTop: '5%' }}>{renderImageOrDefaultText(im?.base64)}</div>
                    ))}
                  </BoxTheme>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ marginTop: 15 }} display={'flex'} justifyContent={'space-between'}>
                    <CustomButton onClick={handleShowReport} fullWidth={true} color={'primary'}>
                      {'INFORME'}
                    </CustomButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} mb={5}>
              <IssuesMap issues={issue ? [issue] : []} center={center} zoom={zoom} />
            </Grid>
            <Grid style={{ marginTop: 20 }} item container xs={12} spacing={2}>
              <ActionsTable issueID={id || ''} />
              <WorknotesTable issueID={id || ''} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  )
}
